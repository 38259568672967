import * as S from "./styles";
import { useTranslation } from "react-i18next";

type AuthLayoutProps = {
  children: any;
};

export default function AuthLayout({ children }: AuthLayoutProps) {
  const { t } = useTranslation();
  return (
    <S.ContentAuth>
      <S.Background>
        <img src="/images/background-login.svg" alt="background" />
      </S.Background>
      <S.ContentText>
        <S.Title>{t("loginForm.titleNegotiation")}</S.Title> &nbsp;
        <S.SubTitle>{t("loginForm.subTitleNegotiation")}</S.SubTitle>
        <S.TextAuth>{t("loginForm.textNegotiation")}</S.TextAuth>
        <S.ContentDownload>
          <h4>{t("loginForm.download")}</h4>
          <S.Download>
            <S.Button
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.transfero.crypto&hl=en_US",
                  "_blank"
                )
              }
            >
              <img src="/images/GooglePlayLogo.svg" alt="" />
              Google Play
            </S.Button>

            <S.Button
              onClick={() =>
                window.open(
                  "https://apps.apple.com/br/app/transfero/id1660074684",
                  "_blank"
                )
              }
            >
              <img src="/images/appStore.svg" alt="" />
              App Store
            </S.Button>
          </S.Download>
        </S.ContentDownload>
      </S.ContentText>

      <S.Auth>{children}</S.Auth>
    </S.ContentAuth>
  );
}
