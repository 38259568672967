import { CryptoMarket, Slider, SliderItem } from "@/widgets";
import { IMarket } from "@/models";
import * as S from "./styles";
import { useMarket } from "@/store/features/market/market-query";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@/components/Skeleton";

export default function CurrencySlider() {
  const { markets } = useMarket();
  const { t } = useTranslation();
  const returnPositiveItem = (percentage: number): boolean =>
    percentage >= 0.01;

  const renderItems = () => {
    return markets
      ?.filter((item: IMarket) => {
        return returnPositiveItem(item.pricePercentChange24H);
      })
      ?.sort(
        (a: IMarket, b: IMarket) =>
          b.pricePercentChange24H - a.pricePercentChange24H
      )
      ?.map((item: IMarket, index: number) => {
        return (
          <SliderItem key={`${item.fromCurrency.id}-${index}`}>
            <CryptoMarket
              name={item.fromCurrency.id}
              value={item.formattedPricePercentChange24H}
              absoluteImageUrl={item.fromCurrency.logoUrl}
              icon="arrowUpGreen"
            />
          </SliderItem>
        );
      });
  };

  if (!markets) {
    return (
      <S.SkeletonContainer>
        <Skeleton width="100%" height="122px" />
      </S.SkeletonContainer>
    );
  }

  return (
    <>
      {renderItems()?.length > 0 && (
        <>
          <S.ContentSliderContainer>
            <h2>{t("availableCurrencies")}</h2>
            <S.Badge24h>
              <p>24h</p>
            </S.Badge24h>
          </S.ContentSliderContainer>

          <S.CurrencySliderContainer>
            <Slider
              settings={{
                slidesToShow: 5,
                className: "slick-track-access",
                dots: true,
              }}
            >
              {renderItems()}
            </Slider>
          </S.CurrencySliderContainer>
        </>
      )}
    </>
  );
}
