import Link from "next/link";
import { Card, Spinner } from "@/widgets";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type AuthFormLayoutProps = {
  children: any;
  isLoading?: boolean;
  loadingMessage?: string;
  hideFooter?: boolean;
  className?: string;
};

export default function AuthFormLayout({
  children,
  isLoading = false,
  loadingMessage,
  hideFooter = false,
  className = "",
}: AuthFormLayoutProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.container} ${
        isLoading ? styles.isLoading : ""
      } ${className}`}
    >
      <Card size="small">
        {isLoading && <Spinner>{loadingMessage}</Spinner>}
        {!isLoading && <>{children}</>}
      </Card>
      {!hideFooter && (
        <p>
          {t("authFormLayout.notRegisteredQuestion")}{" "}
          <Link href="/auth/sign-up">{t("authFormLayout.registerHere")}</Link>
        </p>
      )}
    </div>
  );
}
