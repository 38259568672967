import styled from "styled-components";

export const CurrencySliderContainer = styled.div`
  width: 100%;

  .slick-track-access .slick-list .slick-track {
    margin: 0 !important;
  }

  h2 {
    color: var(--secondary);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      color: var(--text);
      font-size: 16px;
    }
  }
`;

export const ContentSliderContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 28px;
  gap: 16px;
  align-items: center;

  h2 {
    color: #232323;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Badge24h = styled.div`
  display: flex;
  width: 51px;
  height: 22px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #eff1f5;
  p {
    color: #5d6b98;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const SkeletonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 28px;
`;
