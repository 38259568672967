import { deleteCookie } from "cookies-next";
import { Spinner } from "@/widgets";
import { REFRESH_TOKEN_COOKIE_KEY, TOKEN_COOKIE_KEY } from "@/const";
import { AuthFormLayout, AuthLayout } from "@/pages/auth/components";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { GetServerSideProps, GetServerSidePropsContext } from "next";

import { clearBalance, useBalance } from "@/store/features/balance";
import { clearCustomer, useCustomer } from "@/store/features/customer";
import { clearFavorite, useFavorite } from "@/store/features/favorite";
import { queryClient } from "@/libs/react-query";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function LoginPage() {
  const { t } = useTranslation();
  const router = useRouter();

  const { balanceDispatch } = useBalance();
  const { customerDispatch } = useCustomer();
  const { favoriteDispatch } = useFavorite();

  const clearSensitiveFeatures = () => {
    balanceDispatch(clearBalance());
    customerDispatch(clearCustomer());
    favoriteDispatch(clearFavorite());
  };

  useEffect(() => {
    clearSensitiveFeatures();

    router.push("/auth/login");
  }, []);

  return (
    <AuthLayout>
      <AuthFormLayout hideFooter={true} className={styles.container}>
        <Spinner size="small">{t("logoutMessage")}</Spinner>
      </AuthFormLayout>
    </AuthLayout>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  deleteCookie(TOKEN_COOKIE_KEY, { req: context.req, res: context.res });
  deleteCookie(REFRESH_TOKEN_COOKIE_KEY, {
    req: context.req,
    res: context.res,
  });
  await queryClient.invalidateQueries();

  return {
    props: {},
  };
};
