import { api } from "@/apis/axios";
import { useQuery } from "@tanstack/react-query";

export function useMarket() {
  const { data: markets } = useQuery({
    queryKey: ["market"],
    queryFn: async () => {
      const response = await api.get("/markets");
      return response.data;
    },
  });

  return {
    markets,
  };
}
